import React from 'react';
import './About.css'


const Home = () => {
    
    return (
        <div className='overall-container'>
        <div className='text-car'>
        <div className='container'>
   <h2 className="name">Software Engineer, Front End & Mobile App Developer.</h2>
   <h2 className="job-description"> I am a junior software engineer specifically focus on: <span className='middle-words'> Front End & Mobile App Development with React JS & React Native Framework.</span></h2>  
   <img src='./images/image.jpeg' alt="Ansu Kamara"  className='img'/>
   
   <div className='reduce-space'>
            <h4 className="work">Hello, I'm Ansu Kamara!</h4>
    </div>
    
    <div className='paragraph-container'>
      <p className='paragraph'>
         As a junior software engineer, I specialize in front-end development using the React.js framework, as well as mobile app development with React Native. My journey in software engineering began with a passion for creating user-friendly and visually appealing interfaces. I enjoy leveraging my skills in JavaScript and modern web technologies to build responsive web applications that deliver seamless user experiences.

With a solid foundation in React.js, I have developed several projects that showcase strong component architecture and state management, ensuring efficient and maintainable code. My experience with React Native allows me to create cross-platform mobile applications, bridging the gap between web and mobile development.

I am always eager to learn and stay updated on industry trends and best practices, as I believe continuous improvement is key to becoming a better developer. Collaboration and teamwork are essential to me, and I thrive in environments where I can share knowledge and learn from others. I'm excited to contribute my skills and grow as part of a dynamic team, helping to create innovative solutions that meet user needs.
            </p>
        </div>
        <div className='btn-position'>
        <a href='https://drive.google.com/file/d/1qc-xQBiB4JUAzW7vI78wzVLhOa8f1uK6/view' style={{textDecoration: 'none'}}>
        <div className='resume-btn'>Resume</div>
        </a> 
        </div>
        
            </div>
</div>

<div className='group-card'>
<div className='skills-card-container'>
            <h2 className='skills'>Skills</h2> 
            <div className='skills-container'>
        <div className='skills-card'>
         <h8 className='html-name'>HTML</h8>
        </div>
        <div className='skills-card'>
         <h8 className='css-name'>CSS</h8>
        </div>
        <div className='skills-card'>
         <h8 className='javascript-name'>JavaScript</h8>
        </div>
        <div className='skills-card'>
         <h8 className='react-name'>React.JS</h8>
        </div>
        <div className='skills-card'>
         <h8 className='reactnative-name'>React-Native</h8>
        </div>
        <div className='skills-card'>
         <h8 className='firebase-name'>Firebase</h8>
        </div>
        </div>
        </div>
</div>
        
        <div className='project-card-container'>
 <h3 className='project'>My Projects</h3>
            <h3 className='project-heading'>A collection of projects I've worked on:</h3>  
        
        <div className='card-container'>
        <div className='card'>
        <img src='./images/ecommerce.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-commerce'>Ecommerce Site</p>
        <div className='gap'>
        <a href="https://github.com/ansukay01/Online_Market" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Github</h8>
         </a>
         <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
        </div>
        <div className='card'>
        <img src='./images/technews.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-news'>Tech-News Site</p>
        <div className='gap'>
        <a href='https://github.com/ansukay01/FrontPage' style= {{textDecoration: 'none'}}>
        <h8 className='link-btn'>Github</h8>
        </a>
        <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
        </div>
        <div className='card'>
        <img src='./images/banking.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-banking'> Banking App</p>
        <div className='gap'>
        <div className='1link-btn'>
        <a href="https://drive.google.com/file/d/1rWcDkrB2tnitgYLOEJWP9-BwJvyKu9IR/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
        <h8 className='link-btn'>Github</h8>
        </a>
         </div>
        <div className='l2ink-btn'>
        <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
         </div>
        </div>
        <div className='card'>
        <img src='./images/car.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-rental'>Car Rental App</p>
        <div className='gap'>
        <a href='https://github.com/ansukay01/rental-car' style={{textDecoration: 'none'}}>
        <h8 className='link-btn'>Github</h8>
        </a>
        <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
        </div>
        <div className='card'>
        <img src='./images/ride.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-food'>Delivering App</p>
        <div className='gap'>
        <a href="https://drive.google.com/file/d/1rWcDkrB2tnitgYLOEJWP9-BwJvyKu9IR/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
        <h8 className='link-btn'>Github</h8>
        </a>
        <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
        </div>
        <div className='card'>
        <img src='./images/app.jpeg' alt="project-image"  className='project-image'/>
        <div className='background'>
        <p className='title-e'>Ecommerce App</p>
        <div className='gap'>
        <a href='https://github.com/ansukay01/Super_Mall' style={{textDecoration: 'none'}}>
        <h8 className='link-btn'>Github</h8>
        </a>
        <a href="https://drive.google.com/file/d/1rVVrYBtwbrijmO5joYbGnj_eCt6CPhUa/view?usp=drivesdk" style={{ textDecoration: 'none' }}>
         <h8 className='link-btn'>Video</h8>
         </a>
         </div>
         </div>
       </div>
       </div>
       </div>
      </div>
     
    );
    }
     

export default Home;


