import React, { useState } from 'react';
import './NavBar.css';

const Barnav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="brand">
        <h1>ANSU KAMARA</h1>
      </div>
      <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        ☰
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><a href="/">Home</a></li>
        <li><a href="/contact"><span className='contact'>Contact</span></a></li>
      </ul>
    </nav>
  );
};

export default Barnav;
