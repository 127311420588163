import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Barnav from './Components/Nav';
//import Barnav from './Components/Nav';
//
import Home from './Components/Home';
import Contact from './Components/Contact';
import './App.css';

const App = () => {
  return (
    <Router>
    <Barnav />
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;