import React from "react";
import './Contact.css';

const Contact = () => {

  return (
   <div className="Container">
    <div className="Card">
  <h4 className="touch">Get in touch with me via social media or send me an email</h4>
  <div className="font-class">
  <a href="https://www.facebook.com/ansu.kamara.27974"  style={{ textDecoration: 'none' }}>
  <h6 className="social-media">Facebook</h6>
  </a>
  <a href="http://linkedin.com/in/ansukamara27974" style={{ textDecoration: 'none' }}>
  <h6 className="social-media">LinkedIn</h6>
  </a>
  <a href="mailto:ansukamara527@gmail.com" style={{ textDecoration: 'none' }}>
  <h6 className='social-media'>Gmail</h6>
  </a>
  </div>
  <div className="image-class">
  </div>
  <div className='form-class'>
  <div className="form-container">
  <form target="_blank" action="https://formsubmit.co/ansukamara527@gmail.com" method="POST">
  <div class="form-group">
          <input className="label" type="text" name="name"  placeholder="                         Full Name" required />
  </div>
        <div class="form-group">
          <input className="label" type="email" name="email"  placeholder="                      Email Address" required />
        </div>
      
    <div class="form-group">
      <textarea placeholder="Interested in working together? Let's chat!" className="form-control" name="message" rows="20" required></textarea>
    </div>
    <div class="form-group">
    </div>
    <button type="submit" className="msg-btn">Send Message</button>
  </form>
</div>
</div>
</div>
</div>
    
  )
}

export default Contact